// Include all fonts

@import url("//fonts.googleapis.com/css?family=Hind:300,400,500,600,700");

html, body {
  @include font-smoothing(on);
  font-family: "Hind", sans-serif;
  font-weight: 400;
}

h1, h2, h3, h4, h5, h6 {
  color: $c-primary;
  line-height: 1.1;
  margin-bottom: .6em;

  @include bp(sm) {
    margin-bottom: .4em;
  }

  @include bp(md) {
    margin-bottom: .3em;
  }

  @include bp(lg) {
    margin-bottom: .2em;
  }
}

h1 {
  color: $c-primary;
  font-size: $h1-xs-font-size;
  font-weight: $h1-font-weight;

  @include bp(sm) {
    font-size: $h1-sm-font-size;
  }

  @include bp(md) {
    font-size: $h1-md-font-size;
  }

  @include bp(lg) {
    font-size: $h1-lg-font-size;
    letter-spacing: .5px;
  }
}

h2 {
  font-size: $h2-xs-font-size;
  font-weight: $h2-font-weight;

  @include bp(sm) {
    font-size: $h2-sm-font-size;
  }

  @include bp(md) {
    font-size: $h2-md-font-size;
  }

  @include bp(lg) {
    font-size: $h2-lg-font-size;
  }
}

h3 {
  font-size: $h3-xs-font-size;
  font-weight: $h3-font-weight;

  @include bp(sm) {
    font-size: $h3-sm-font-size;
  }

  @include bp(md) {
    font-size: $h3-md-font-size;
  }

  @include bp(lg) {
    font-size: $h3-lg-font-size;
    letter-spacing: .25px;
  }
}

h4 {
  font-size: $h4-xs-font-size;
  font-weight: $h4-font-weight;

  @include bp(sm) {
    font-size: $h4-sm-font-size;
  }

  @include bp(md) {
    font-size: $h4-md-font-size;
  }

  @include bp(lg) {
    font-size: $h4-lg-font-size;
  }
}

h5 {
  font-size: $h5-xs-font-size;
  font-weight: $h5-font-weight;

  @include bp(sm) {
    font-size: $h5-sm-font-size;
  }

  @include bp(md) {
    font-size: $h5-md-font-size;
  }

  @include bp(lg) {
    font-size: $h5-lg-font-size;
    letter-spacing: .15px;
  }
}

h6 {
  font-size: $h6-xs-font-size;
  font-weight: $h6-font-weight;

  @include bp(sm) {
    font-size: $h6-sm-font-size;
  }

  @include bp(md) {
    font-size: $h6-md-font-size;
  }

  @include bp(lg) {
    font-size: $h6-lg-font-size;
  }
}

p, li {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  color: $c-primary;
  margin: 1em 0;
  letter-spacing: .5px;

  @include bp(sm) {
    margin: .8em 0;
  }

  @include bp(md) {
    margin: .6em 0;
  }

  @include bp(lg) {
    margin: .8em 0;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

strong {
  font-weight: 500;
}

em {
  font-style: italic;
}

a {
  color: $c-light-green;
  transition: $tr-normal;

  &:not([href]) {
    color: $c-primary;
  }

  &:hover, &:focus {
    color: $c-primary;
    text-decoration: none;
  }
}

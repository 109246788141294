.menu {

  @include bp(sm) {
    display: inline-block;
  }

  display: none;
  height: 100%;

  // Top-level navigation
  ul {
    display: inline-flex;
    align-items: center;

    li {
      position: relative;
      list-style-type: none;
      margin-bottom: 0;
      margin-top: 0;

      &:hover {
        ul {
          display: block;
          opacity: 1;
          visibility: visible;
          transform: translate3d(0, 0, 0);
        }
      }

      a {
        @include bp(sm) {
          line-height: $header-sm-height;
        }

        @include bp(md) {
          font-size: 24px;
        }

        @include bp(lg) {
          line-height: $header-lg-height;
        }

        display: block;
        position: relative;
        color: $c-primary;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none;
        transition: $tr-normal;
        line-height: $header-xs-height;
        padding: 0 25px;



        &:hover {
          color: $c-light-green;
        }
      }

      // Second-level navigation
      ul {

        @include bp(lg) {
          top: 75%;
        }

        position: absolute;
        display: block;
        top: 100%;
        min-width: 190px;
        height: auto;
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transform: translate3d(0, -15px, 0);
        transition: $tr-normal;
        box-shadow: $bs-normal;
        border-radius: $br-normal;
        overflow: hidden;
        background: $c-white;

        &::before {
          content: "";
          width: 100%;
          height: 10px;
          background: $c-invi-black;
        }

        li {
          @include mq(large) {
            padding: 12.5px 10px;
          }

          @include bp(sm) {
            font-size: 16px;
            padding: 0 12px;
            line-height: 45px;
          }

          @include bp(md) {
            font-size: 16px;
            padding: 0 12px;
            line-height: 45px;
          }

          @include bp(lg) {
            font-size: 16px;
            padding: 0 12px;
            line-height: 45px;
          }

          display: block;
          width: 100%;
          background: $c-white;
          padding: 0;

          a {
            display: block;
            font-size: 16px;
            line-height: 24px;
            padding: 10px 0;
          }
        }
      }

      &:last-child {
        ul {
          right:0;
        }
      }
    }
  }
}

.header-fixed {
  .menu {
    ul {
      li {
        &:hover {
          ul {
            display: block;
            opacity: 1;
            visibility: visible;
            transform: translate3d(0, 15px, 0);
          }
        }

        a {
          @include bp(sm) {
            line-height: $header-fixed-sm-height;
          }

          @include bp(lg) {
            line-height: $header-fixed-lg-height;
          }
        }

        ul {
          @include bp(lg) {
            top: 100%;
          }

          transform: translate3d(0, 0, 0);

          li {
            a {
              line-height: 45px;
            }
          }
        }
      }
    }
  }
}

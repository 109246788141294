.hero {
  position: relative;
  width: 100%;

  &::after {
    background: url("./../images/wave-light.svg") 50% 50% / cover;
    content: "";
    display: block;
    width: 100%;
    height: auto;
    padding-top: 11.72%;
  }

  .hero-decor {
    padding-top: 80px;
    width: 100%;
    height: auto;
    background: $c-turqouise;

    @include bp(sm) {
      padding-top: 160px;
    }

    @include bp(lg) {
      padding-top: 120px;
    }
  }
}

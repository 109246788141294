$breakpoints: (
  "xsmall": 500px,
  "small":  767px,
  "medium": 1100px,
  "large":  1400px
) !default;

@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
    + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin bp($size) {
  @if $size == sm {
    @media (min-width: $screen-sm) { @content; }
  } @else if $size == ms {
    @media (min-width: 480px) { @content; }
  } @else if $size == md {
    @media (min-width: $screen-md) { @content; }
  } @else if $size == lg {
    @media (min-width: $screen-lg) { @content; }
  }
}

@mixin prefix($property, $val) {
  -webkit-#{$property}: $val;
  #{$property}: $val;
}

@mixin font-smoothing($value: on) {
  @if $value == on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.menu-icon {
  &.hamburger {
    @include bp(sm) {
      display: none;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 25px;
    height: 16px;

    .line {
      width: 100%;
      height: 2px;
      background: $c-primary;
    }
  }

  &.cross {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      font-size: 20px;
      color: $c-primary;
    }
  }
}

.employee-card {
  position: relative;
  width: 100%;
  height: auto;
  background: $c-white;
  padding: 95px 15px 20px;
  box-shadow: $bs-normal;
  border-radius: $br-large;
  margin-top: 75px;
  text-align: center;

  @include bp(sm) {
    padding: 110px 30px 35px;
  }

  @include bp(lg) {
    padding: 115px 35px 40px;
  }

  .employee-card-image {
    position: absolute;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: $bs-normal;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .employee-card-name {
    font-size: 22px;
    margin-bottom: .4em;

    @include bp(sm) {
      font-size: 25px;
    }

    @include bp(lg) {
      font-size: 28px;
    }
  }

  .employee-card-info {
    display: block;
    font-size: 19px;
    margin-bottom: .6em;

    @include bp(sm) {
      font-size: 22px;
    }

    &.employee-card-title {
      color: lighten($c-primary, 5%);
      margin-bottom: 1em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

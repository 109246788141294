.post-detail {
  .post-detail-header {
    h1 {
      max-width: $mw-title-block;
      margin-bottom: .2em;
    }

    p {
      font-size: 13px;
      color: $c-light-grey;
      margin: 10px 0;
    }
  }

  .post-detail-content {
    max-width: 900px;

    .cms-plugin {
      margin-bottom: 40px;
    }
  }
}

.app-detail {
  .app-detail-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .app-detail-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 1em;
      flex-shrink: 0;
      flex-grow: 0;

      @include bp(sm) {
        width: 150px;
        height: 150px;
        margin-right: 2.5em;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .app-detail-title {
      font-size: 20px;
      margin-bottom: 0;

      @include bp(sm) {
        font-size: 36px;
      }

      @include bp(md) {
        font-size: 50px;
      }
    }
  }
}


@keyframes type-marker {
  40%,
  80% {
    opacity: 1;
  }

  50%,
  70% {
    opacity: 0;
  }
}

#case-switch {
  &::after {
    display: inline-block;
    content: "|";
    opacity: 1;
    animation: type-marker 1s infinite;
  }
}

// Import bootstrap 4
@import "bootstrap/dist/css/bootstrap.css";
@import "bootstrap/dist/css/bootstrap-grid.css";
//@import "~bootstrap/dist/css/bootstrap-reboot.css"; //Not needed currently


// Import all base files
@import "src/scss/base/all";

// Import all partials files
@import "src/scss/partials/all";

// Import all widgets files
@import "src/scss/widgets/all";


body {
  overflow-x:hidden;
}

ul, ol {
  margin: 1em 0;

  li {
    list-style-position: inside;
  }

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

ul {
  li {
    list-style-type: disc;
  }
}

ol {
  li {
    list-style-type: decimal;
  }
}

form {
  ul {
    margin: 5px 0;
  }

  ul li {
    list-style: none;
  }
}

.aspect-ratio-container {
  height: 0;
  overflow: hidden;
  padding-top: var(--aspect-ratio);
  position: relative;

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
.latest-news {
  margin-bottom: 40px;

  .latest-news-header {
    width: 100%;
    text-align: center;
  }

  .latest-news-container {
    @include bp(sm) {
      margin: -15px 100px;
    }

    @include bp(lg) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .latest-news-card {
      margin-bottom: 30px;

      .latest-news-card-wrap {
        @include bp(sm) {
          border-radius: $br-large;
        }

        position: relative;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        width: 100%;
        height: 100%;
        background: $c-white;
        box-shadow: $bs-normal;
        text-decoration: none;
        border-radius: $br-medium;
        overflow: hidden;
        z-index: 1;
        transition: $tr-normal;

        &:hover {
          transform: translate3d(0, -15px, 0);
          box-shadow: $bs-hover;
        }

        .latest-news-image-container {
          position: relative;
          width: 100%;
          height: auto;
          padding-top: (139 / 185) * 100%;

          .latest-news-image {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;

            img {
              width: 100%;
              height: auto;
            }
          }
        }

        .latest-news-label {
          @include bp(sm) {
            padding: 35px 30px;
          }

          @include bp(lg) {
            padding: 40px 35px;
          }

          width: 100%;
          height: auto;
          padding: 20px 15px;

          span {
            display: block;
            font-size: 16px;
            color: $c-green;
            margin-bottom: 10px;
          }

          h3 {
            font-size: 26px;
            margin: 0;
            margin-bottom: 10px;
          }

          p {
            font-size: 18px;
          }
        }
      }
    }
  }

  .latest-news-more {
    display: flex;
    justify-content: center;
  }
}

.header {
  // position
  position: relative;
  z-index: $zi-infront;
  right: 0;
  left: 0;
  // other
  height: $header-xs-height;
  background: $c-invis-white;
  transition: $tr-normal;
  margin-bottom: -$header-xs-height;
  // flex
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;

  @include bp(sm) {
    height: $header-sm-height;
    margin-bottom: -$header-sm-height;
  }

  @include bp(md) {
    padding: 0 40px;
  }

  @include bp(lg) {
    height: $header-lg-height;
    margin-bottom: -$header-lg-height;
  }

  &.menu-is-active {
    transform: translate3d(-270px, 0, 0);
  }

  .header-logo {
    display: flex;
    align-items: center;
    height: 100%;

    img {
      height: 60%;
    }
  }

  &.header-fixed {
    position: fixed;
    animation: slideHeader .5s;
    background: $c-white;
    border-bottom: 1px solid darken($c-white, 5%);

    @include bp(sm) {
      height: $header-fixed-sm-height;
    }

    @include bp(lg) {
      height: $header-fixed-lg-height;
    }

    .header-logo {
      animation: slideLogo 1s;
    }
  }
}

@keyframes slideLogo {
  from {
    transform: translate3d(-60px, 0, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes slideHeader {
  from {
    transform: translate3d(0, -100%, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

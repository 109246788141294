.card {
  width: 100%;
  background: $c-white;
  box-shadow: $bs-normal;
  border-radius: $br-medium;
  padding: 35px 30px;

  @include bp(lg) {
    padding: 40px 35px;
  }
}

.has-card {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-direction: column;
}

.multi-column.has-card {
  flex-direction: column;

  @include bp(sm) {
    flex-direction: row;
  }
}

.cases {
  text-align: center;

  .cases-more {
    display: flex;
    justify-content: center;
  }
}

.scale-anm {
  transform: scale(1);
}

@media all and (max-width: 768px) {
  .filter-bar > button {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.filter-bar {
  display: flex;
  flex-direction: column;
  place-items: flex-start;
  margin-bottom: 20px;
  flex-wrap: wrap;

  .filter-buttons {
    flex-direction: row;

    .filter-button {
      background: $c-primary;
      margin-right: 1em;
      margin-bottom: 5px;

      &:focus {
        outline: 0;
      }

      &:hover {
        cursor: pointer;
      }

      &.active,
      &:hover {
        background: $c-light-green;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }
}

.post-meta {
  width: 100%;
  height: auto;

  .post-meta-tags {
    width: 100%;
    height: auto;
    font-size: 0;

    .post-meta-tag {
      display: inline-block;
      vertical-align: top;
      margin: 0 10px 10px 0;

      &:hover {
        a {
          text-decoration: none;
          background: $c-light-green;
          color: $c-white;
        }
      }

      a {
        display: block;
        font-size: 13px;
        padding: 4px 8px;
        line-height: 1;
        background: transparent;
        border: 1px solid $c-light-green;
        border-radius: $br-medium;
        color: $c-light-green;
        transition: color $tr-normal, background $tr-normal;
      }
    }
  }
}

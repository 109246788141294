$c-primary: #004d40;
$c-turqouise: #d4fbf4;
$c-dark-green: #004d40;
$c-green: #009a81;
$c-light-green: #3bd8b6;
$c-white: #fff;
$c-light-grey: #b5b5b5;
$c-red: #ff0000;
$red: #f00;
$c-black: #000;
$c-invis-white: rgba(255, 255, 255, 0);
$c-invi-black: rgba(0, 0, 0, 0);

.resource-card {
  position: relative;
  width: 100%;
  height: 550px;
  background: $c-white;
  box-shadow: $bs-normal;
  border-radius: $br-medium;
  overflow: hidden;
  margin-bottom: 30px;
  transition: $tr-normal;
  padding: 10px 15px;

  @include bp(sm) {
    border-radius: $br-large;
  }

  &:hover {
    transform: translate3d(0, -8px, 0);
    box-shadow: $bs-hover;
  }

  .resource-card-image-container {
    position: relative;
    width: 100%;
    height: 55%;
    //padding: 10% 10%;
    overflow: hidden;
    display: flex;
    justify-content: center;

    .resource-card-image {
      display: flex;
      justify-content: center;
      padding: 10px 0;
      align-items: center;


      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .resource-card-label-container {
    position: relative;
    width: 100%;
    height: auto;
    padding-top: (37 / 135) * 100%;

    .resource-card-label {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: 18px;
        line-height: 1;
        margin-top: 0;
        margin-bottom: 0;
        color: $c-dark-green;

        @include bp(ms) {
          font-size: 14px;
        }

        @include bp(sm) {
          font-size: 18px;
        }

        @include bp(md) {
          font-size: 14px;
        }

        @include bp(lg) {
          font-size: 16px;
        }
      }

      span {
        color: $c-primary;
        line-height: 1;
        font-size: 16px;
        transition: color $tr-normal;
      }
    }
  }

  .resource-card-description {
    display: flex;
  }
}

.employee-card-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .employee-card-list-item {
    display: flex;
    margin-bottom: 30px;
  }
}

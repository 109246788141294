.cms-toolbar-expanded {
  .off-page-menu {
    top: 61px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zi-infront-most;
  background: $c-black;
  opacity: 0;
  height: 0;
  transition: $tr-overlay;

  &.is-active {
    height: 100%;
    opacity: .6;
  }
}

.off-page-menu {
  position: fixed;
  z-index: $zi-infront-all;
  top: 15px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  background: $c-white;
  transition: $tr-mobile-nav;
  opacity: 0;
  height: 0;
  overflow: hidden;
  transform: scale(0);
  border-radius: $br-large;

  &.is-active {
    opacity: 1;
    height: auto;
    overflow: scroll;
    transform: scale(1);
  }

  .off-page-menu-logo {
    display: block;

    img {
      display: block;
      height: 35px;
      margin: 40px auto 20px;
    }
  }

  ul {
    width: 100%;
    text-align: center;
    margin: 0;
    height: calc(100% - 125px);
    overflow-y: scroll;

    li {
      line-height: 1;
      list-style-type: none;
      margin: 0;

      a {
        display: block;
        font-size: 20px;
        font-weight: 600;
        color: $c-primary;
        line-height: 1;
        padding: 13px;

        &:hover {
          background: darken($c-white, 1.5%);
        }
      }

      ul {
        height: auto;
        padding-bottom: 0;

        li {
          a {
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

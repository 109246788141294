.text-image {
  // @extend .container;

  &--inner {
    // @extend .inner;
    align-items: center;
    display: flex;
  }

  &--item {
    flex-basis: 50%;
    flex-grow: 0;
    flex-shrink: 0;
    height: auto;

    img {
      float: right;
      width: 100%;
      max-width: 260px;
    }

    &--image {
      padding-right: 30px;

      img {
        width: 100%;
        height: auto;
        max-width: none;
      }
    }
  }

  &--logo {
    height: 50px;
    float: right;
    margin-top: 30px;
  }
}

.pagination {
  .pagination-inner {
    text-align: center;

    .pagination-previous,
    .pagination-current,
    .pagination-next {
      font-size: 16px;
    }

    .pagination-previous {
      margin-right: 5px;
    }

    .pagination-next {
      margin-left: 5px;
    }
  }
}

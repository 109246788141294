.resource-card-tag {
  display: inline-flex;
  margin-right: 1em;
  margin-top: 10px;
  margin-bottom: 5px;
  align-content: center;
  width:fit-content;
  flex-direction: row;


  .tag-label{
    color: $c-light-grey;
    background: transparent;
    align-content: center;
  }

}


.app-item {
  position: relative;
  width: 100%;
  height: auto;
  background: $c-white;
  padding: 70px 15px 20px;
  box-shadow: $bs-normal;
  border-radius: $br-large;
  margin-top: 75px;
  transition: $tr-normal;

  &:hover {
    box-shadow: $bs-hover;
    transform: translate3d(0, -8px, 0);
    text-decoration: none;
  }

  @include bp(sm) {
    padding: 100px 30px 35px;
  }

  @include bp(lg) {
    padding: 115px 35px 40px;
  }

  .app-item-image {
    position: absolute;
    top: -50px;
    left: 65px;
    transform: translateX(-50%);
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: $bs-normal;
    background: $c-white;

    @include bp(sm) {
      width: 130px;
      height: 130px;
      top: -65px;
      left: 95px;
    }

    @include bp(lg) {
      width: 150px;
      height: 150px;
      top: -75px;
      left: 110px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  * {
    text-decoration: none;
  }
}

.page-wrapper {
  width: 100%;
  height: auto;
  overflow: hidden;
  transition: $tr-normal;

  &.menu-is-active {
    transform: translate3d(-270px, 0, 0);
  }
}

.footer {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;

  &,
  & a {
    color: $c-white;
  }

  &::before {
    position: relative;
    top: 3px;
    content: "";
    display: block;
    width: 100%;
    height: auto;
    padding-top: 11.72%;
    background: url("./../images/wave-dark.svg") 50% 50% / cover;
  }

  p {
    color: $c-white;
  }

  .row {
    margin-bottom: 0;
  }

  .footer-inner {
    background: $c-primary;
    padding-top: 0;
    padding-bottom: 0;

    .footer-decor {
      position: relative;
      padding-top: 40px;
      padding-bottom: 60px;

      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        z-index: $zi-behind-all;
        transform: translateX(-50%);
        width: 3000%;
        height: 3000%;
        background: $c-primary;
      }
    }

    .footer-logo {
      display: flex;
      justify-content: center;

      @include bp(sm) {
        justify-content: flex-start;
      }

      img {
        height: 50px;

        @include bp(sm) {
          height: 35px;
        }

        @include bp(md) {
          height: 50px;
        }
      }
    }

    .lists {
      text-align: center;
      margin-bottom: 60px;

      @include bp(sm) {
        text-align: left;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: $c-white;
        font-size: 18px;
        margin-bottom: .6em;
      }

      p,
      span,
      a,
      li {
        display: block;
        font-size: 15px;
        font-weight: 300;
        color: $c-white;
        opacity: .9;
        text-decoration: none;
        margin-bottom: .6em;
        line-height: 1.2;

        &:last-child {
          margin-bottom: 0;
        }
      }

      a {
        transition: $tr-normal;

        &:hover {
          opacity: 1;
        }
      }

      .cms-text,
      .column {
        margin-bottom: 0;

        & a {
          margin-bottom: .6em;
        }

        & li {
          margin: 0;
          margin-bottom: .6em;
        }

        &.column {
          margin-bottom: 30px;
        }

        ul {
          margin: 0;
        }
      }

      script {
        display: none;
      }
    }

    .icons,
    .lists {
      [class*="col-"] {
        margin-bottom: 30px;

        &:last-child {
          margin-bottom: 0;
        }

        @include bp(sm) {
          margin-bottom: 0;
        }
      }
    }

    .footer-social {
      ul {
        width: 100%;
        text-align: center;

        @include bp(sm) {
          text-align: right;
        }

        li {
          display: inline-block;
          height: 35px;
          margin: 0 5px;

          @include bp(sm) {
            margin: 0;
            margin-left: 10px;
          }

          @include bp(md) {
            height: 40px;
          }

          a {
            height: 100%;

            &:hover {
              img {
                opacity: 1;
                transform: scale(1.05);
              }
            }

            img {
              height: 100%;
              opacity: .9;
              transition: $tr-normal;
            }
          }
        }
      }
    }
  }
}

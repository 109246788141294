input[type="button"],
input[type="submit"],
button,
.button,
.hs-button {
  // Base
  border: 0;
  position: relative;
  z-index: 1;
  backface-visibility: hidden;
  display: inline-block;
  padding: 1.15em 2.2em .9em;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: .75px;
  text-decoration: none;
  overflow: hidden;
  box-shadow: $bs-normal;
  border-radius: 999px;
  transition: $tr-normal;
  line-height: 1;

  // Green button
  background: $c-primary;
  color: $c-white;

  &:hover,
  &:active,
  &:focus {
    background: $c-light-green;
    text-decoration: none;
  }

  &.button-under-section {
    margin-top: 15px;
  }
}

form {
  div.field.hs-form-field > div.input > input.hs-input:not([type="submit"]):not([type="button"]):not([type="radio"]):not([type="checkbox"]), // Be as specific as possible
  input,
  textarea,
  select {
    margin: .5em 0;
    width: 100%;
    border: 1px solid transparent;
    border-radius: $br-normal;
    padding: 13px 18px;
    box-shadow: $bs-normal;
    transition: $tr-normal;
    color: $c-dark-green;
    font-size: 1.2em;

    &:focus {
      border-color: $c-light-green;
      outline: none;
    }
  }

  textarea {
    height: 150px;
    resize: vertical;
  }

  select {
    background: $c-white;
  }

  label {
    margin-bottom: .4em;
    text-align: left;
    font-size: 16px;
  }

  .error {
    input,
    textarea {
      border-color: $red;
    }
  }

  [required] {
    label {

      &::before {
        content: " * ";
      }
    }
  }

  input[type="button"],
  input[type="submit"],
  button,
  .button {
    margin: 2em auto 0;
    display: block;
  }

  .hs-submit {
    display: flex;
    justify-content: center;
  }
}
.hbspt-form{
  .hs-form {
    width: 100%;
    height: auto;
    margin: -15px 0;

    fieldset[class^="form-columns"] {
      margin: 0 -15px;
      padding: 15px 0;
      max-width: 100%;

      .field {
        padding: 0 15px;

        &.hs-fieldtype-select {
          .input {
            position: relative;

            &::after {
              content: "\25BC";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 40px;
              cursor: pointer;
              pointer-events: none;
              transition: .25s all ease;
              text-align: center;
              line-height: 62px;
              font-size: 12px;
              color: #808080;
            }
          }
        }

        label {
          color: $c-primary;
        }

        .input {
          margin: 0;

          select {
            display: block;
            position: relative;
            /* stylelint-disable-next-line property-no-vendor-prefix */
            -webkit-appearance: none;
            width: 100%;
            padding: 13px 40px 13px 18px;
            font-size: 1.2em;
            line-height: 30px;
            color: #808080;

            &::-ms-expand {
              display: none;
            }
          }

          textarea {
            width: 100%;
          }
        }

        .hs-error-msgs {
          li {
            .hs-error-msg {
              color: $red;
            }
          }
        }
      }
    }
  }
}

// Settings
//$static-url: "/static/";

// Box shadow
$bs-normal: 0 10px 20px rgba(0, 0, 0, .1), 0 6px 6px rgba(0, 0, 0, .1);
$bs-hover: 0 14px 28px rgba(0, 0, 0, .1), 0 10px 10px rgba(0, 0, 0, .1);

// Border radius
$br-normal: 4px;
$br-medium: 10px;
$br-large: 20px;

// Transition
$tr-normal: all .3s cubic-bezier(.25, .8, .25, 1);
$tr-overlay: opacity .3s cubic-bezier(.25, .8, .25, 1);
$tr-mobile-nav: opacity .3s cubic-bezier(.25, .8, .25, 1), transform .3s cubic-bezier(.25, .8, .25, 1);

// Z-index
$zi-infront-all: 999;
$zi-infront-most: 700;
$zi-infront: 500;
$zi-behind-all: -1;

// Max widths
$mw-title-block: 900px;
$mw-text-block: 650px;

// Header heights
$header-xs-height: 50px;
$header-sm-height: 80px;
$header-lg-height: 120px;
$header-fixed-sm-height: 60px;
$header-fixed-lg-height: 80px;

// Bootstrap
$grid-gutter-width: 30px;
$container-large-desktop: (1170px + $grid-gutter-width);
$container-desktop: (980px + $grid-gutter-width);

$screen-sm: 768px !default;
$screen-md: 992px !default;
$screen-lg: 1200px !default;

$font-family-sans-serif: "Hind", sans-serif !default;
$headings-color: $c-primary;


// Font Sizes

$h1-xs-font-size: 36px;
$h1-sm-font-size: 40px;
$h1-md-font-size: 46px;
$h1-lg-font-size: 60px;

$h2-xs-font-size: 27px;
$h2-sm-font-size: 32px;
$h2-md-font-size: 36px;
$h2-lg-font-size: 48px;

$h3-xs-font-size: 25px;
$h3-sm-font-size: 29px;
$h3-md-font-size: 33px;
$h3-lg-font-size: 34px;

$h4-xs-font-size: 22px;
$h4-sm-font-size: 18px;
$h4-md-font-size: 20px;
$h4-lg-font-size: 24px;

$h5-xs-font-size: 20px;
$h5-sm-font-size: 23px;
$h5-md-font-size: 25px;
$h5-lg-font-size: 28px;

$h6-xs-font-size: 20px;
$h6-sm-font-size: 23px;
$h6-md-font-size: 25px;
$h6-lg-font-size: 16px;


// Font Weight

$h1-font-weight: 300;
$h2-font-weight: 300;
$h3-font-weight: 400;
$h4-font-weight: 400;
$h5-font-weight: 500;
$h6-font-weight: 500;
